$textColor: #232222;
$titleColor: #31568a;
$backgroundColor: #d9d9d98c;
$backgroundColorNav: #23a7d7;

.overons-container {
  width: 100%;
  height: auto;
  background-color: $backgroundColor;
  overflow: hidden;
  .overons-wrap {
    align-items: center;
    padding: 5% 150px;
    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .image {
    img {
      width: 550px;
      height: 530px;
      border: #9dcfda 25px solid;
      border-radius: 100%;
    }
  }
  h1 {
    text-decoration: none;
    position: relative;
    font-size: 2rem; /* adjust to match your font size */
    font-weight: 700; /* adjust to match your font weight */
    color: $titleColor; /* adjust to match your text color */
    display: inline-block;
    padding-bottom: -90%; /* adjust to match your desired distance between text and underline */
    line-height: 40px;
    z-index: 1;
  }

  h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px; /* adjust to match your desired line thickness */
    background-color: #25a18ea7; /* adjust to match your desired line color */
    border-radius: 50px; /* adjust to match your desired roundedness of the edges */
    z-index: -1; /* move the underline behind the text */
    transition: all ease-in-out 1s;
  }

  .text {
    width: 40%;
    .bio {
      margin: 10px 0px;
      h3 {
        font-weight: 200;
        line-height: 40px;
        font-size: 1.5rem;
      }
    }
  }
  .balk {
    position: absolute;
    top: 100;
    right: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .overons {
    padding: 8% 0%;
    .overons-wrap {
      padding: 8% 20px;
      .wrap {
        display: block;
      }
    }
    .image {
      img {
        height: 325px;
        border: #9dcfda 18px solid;
        border-radius: 100%;
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
    }
    .image {
      display: flex;
      justify-content: center;
      img {
        width: 90%;
      }
    }
    .text {
      width: 100%;
      margin: 50px 0px;
      .bio {
        margin: 10px 0px;
        h3 {
          font-size: 1.12rem;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
    button {
      margin-top: 10px;
    }
  }
  .balk {
    display: none;
  }
}
