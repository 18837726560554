$navTextColor: #232222;
$headerBackgroundColor: #e9e9e9;
$headerBackgroundColorNav: #23a7d7;

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 150px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 150px;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in-out;
}

.home-link {
  display: none;
}

.logo {
  width: 10%;
  z-index: 999;
  transition: all 0.3s ease-in-out;
}

nav {
  margin-left: auto;
  margin-right: -30px;
}

nav a {
  margin: 0 2rem;
  font-size: 1.16rem;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease-in-out, top 0.2s ease-in-out;
  position: relative;
  top: 0;
  cursor: pointer;
}

nav a:hover {
  top: -5px;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: $navTextColor;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.logo-link {
  display: inline-block;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.logo-link:hover .logo {
  transform: translateY(-5px);
}

@media only screen and (max-width: 1024px) {
  header {
    padding: 0 20px;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  .home-link {
    display: block;
  }
  .logo {
    position: relative;
    z-index: 999;
    width: 15%;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;
    background-color: $headerBackgroundColorNav;
    transition: 0.8s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
    color: $headerBackgroundColor !important;
  }
}
