$textColor: #232222;
$titleColor: #31568a;
$backgroundColor: #e9e9e9;
$backgroundColorNav: #23a7d7;

.assortiment-container {
  .assortiment-wrap {
    padding: 4% 150px;
    overflow: hidden;
  }
  .assortiment-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fish-image {
    padding: 2% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70%;
    }
  }
  .assortiment {
    padding-left: 8%;
    padding-top: 5%;
    display: flex;
    justify-content: center;
    .kader {
      background-color: #d9d9d98c;
      margin-right: 5%;
      border-radius: 30px;
      height: 500px;
      width: 400px;
      .title-vis {
        padding: 10px;
        border-radius: 50px;
        h1 {
          font-size: 1.8rem;
          color: $backgroundColor;
          font-weight: 700;
          display: flex;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: green;
      }
      .vers {
        background-color: #25a18e;
      }
      .bevroren {
        background-color: #32558b;
      }
      .opgelegd {
        background-color: #23a7d7;
      }
    }
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    color: $titleColor;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: -90%; /* adjust to match your desired distance between text and underline */
    line-height: 40px;
  }

  h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 2;
    width: 18%;
    height: 20px; /* adjust to match your desired line thickness */
    background-color: #25a18ea7; /* adjust to match your desired line color */
    border-radius: 50px; /* adjust to match your desired roundedness of the edges */
    z-index: -1;
  }

  .text {
    p {
      padding: 10% 15%;
      font-weight: 300;
      color: $textColor;
      line-height: 30px;
      font-size: 1.12rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .balk {
    position: absolute;
    top: 180%;
    left: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .assortiment-container {
    .balk {
      display: none;
    }
    .fish-image {
      padding: 0;
      margin: 0;
      display: none;
    }
    .assortiment-wrap {
      padding: 4% 5%;
    }
    .assortiment-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
    }
    .image {
      padding: 2% 0%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .assortiment {
      flex-wrap: wrap;
      padding-top: 4%;
      padding-left: 0%;
      .kader {
        background-color: #d9d9d98c;
        margin: 2% 2%;
        border-radius: 30px;
        height: 300px;
        width: 300px;
        margin-bottom: 10%;
        .title-vis {
          padding: 5px;
          border-radius: 50px;
          h1 {
            font-size: 1.5rem;
            color: $backgroundColor;
            font-weight: 700;
            display: flex;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: green;
        }
        .vers {
          background-color: #25a18e;
        }
        .bevroren {
          background-color: #32558b;
        }
        .opgelegd {
          background-color: #23a7d7;
        }
      }
    }
    h1 {
      font-size: 1.8rem;
      font-weight: 700;
      color: $titleColor;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: $titleColor;
      text-decoration: none;
      position: relative;
      display: inline-block;
      padding-bottom: -90%; /* adjust to match your desired distance between text and underline */
      line-height: 40px;
    }

    h1::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 2;
      width: 80%;
      height: 20px; /* adjust to match your desired line thickness */
      background-color: #25a18e92; /* adjust to match your desired line color */
      border-radius: 50px; /* adjust to match your desired roundedness of the edges */
      z-index: -1; /* move the underline behind the text */
    }
    .text {
      p {
        padding: 5%;
        font-weight: 300;
        color: $textColor;
        line-height: 30px;
        font-size: 1.12rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
