$contactTextColor: #232222;
$contactTitleColor: #31568a;
$contactBackgroundColor: #d9d9d98c;

.contact-container {
  padding: 8% 20px;
  width: 100%;
  height: 1300px;
  background-color: $contactBackgroundColor;
  overflow: hidden;
}

.contact-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: $contactTitleColor;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-bottom: -90%; /* adjust to match your desired distance between text and underline */
  line-height: 40px;
  z-index: 1;
}

.contact-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; /* adjust to match your desired line thickness */
  background-color: #25a18ea7; /* adjust to match your desired line color */
  border-radius: 50px; /* adjust to match your desired roundedness of the edges */
  z-index: -1;


}

h2 {
  color: #25a18e;
  font-weight: 500;
  font-size: 1.7rem;
  padding-bottom: 5%;
}

.bio {
  margin-bottom: 2rem;
}

.openings-details {
  display: flex;
  flex-wrap: wrap;
}

.openingsuren,
.details {
  flex-basis: 50%;
}

.box {
  margin-bottom: 2rem;
}

.opening {
  display: flex;
}

.dag {
  flex-basis: 100%;
}

.uren {
  flex-basis: 60%;
}

.opening p {
  font-weight: 300;
  color: $contactTextColor;
  line-height: 30px;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  a {
    text-decoration: none;
    color: inherit;
    display: flex;
  }
}

.telephone img,
.mail img {
  height: 1.5rem;
  margin-right: 1rem;
}

.social-icons img {
  height: 2rem;
  margin-right: 1rem;
}

.contact-description {
  margin-bottom: 2rem;
  p {
    font-size: 1.12rem;
    font-weight: 200;
    line-height: 30px;
    color: $contactTextColor;
  }
}

.telephone {
  display: flex;
}
.mail {
  display: flex;
}

.google iframe {
  display: flex;
  width: 100%;
  height: 600px;
}

.balk2 {
  display: none;
}

@media screen and (min-width: 768px) {
  .contact-description {
    p {
      font-weight: 200;
      line-height: 40px;
      font-size: 1.5rem;
    }
  }
  .contact-container {
    padding: 4% 150px;
  }
  .contact-details {
    display: flex;
  }

  .contact-wrap {
    max-width: 800px;
    margin: 0 auto;
  }

  .openings-details {
    display: flex;
  }

  .openingsuren,
  .details {
    flex-basis: 50%;
  }

  .box {
    margin-bottom: 0;
  }

  .details {
    margin-left: 1rem;
  }

  h2 {
    color: #25a18e;
    font-weight: 500;
    font-size: 1.7rem;
    padding-bottom: 3%;
  }

  .opening p {
    font-weight: 300;
    color: $contactTextColor;
    line-height: 30px;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .icon-value {
    font-weight: 300;
    color: $contactTextColor;
    line-height: 30px;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .icon-link {
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .icon-link:hover {
    transform: translateY(-5px);
  }

  .details {
    margin-top: 0.6%;
  }

  .contact-icons {
    justify-content: flex-start;
    gap: 60px;
    margin-bottom: 1rem;
  }

  .social-icons {
    img {
      height: 2rem;
      margin-right: 1rem;
    }
  }

  #dinsdag {
    margin-top: 8%;
  }

  #uur {
    margin-top: 13%;
  }
  .google {
    width: 100%;
    height: 300px;
  }
  .google iframe {
    padding-top: 3%;
    display: flex;
    width: 95%;
    height: 800px;
  }
  .balk2 {
    display: block;
    position: absolute;
    top: 313%;
    right: 0;
  }
}
