.credits {
  width: 84%;
  margin-left: 6%;
}

a {
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline;
}

.author::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #bd3656;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.author:hover::before {
  visibility: visible;
  width: 100%;
}

.container {
  border-top: 1px solid black;
  padding: 2% 0px;
  display: flex;
  justify-content: space-between;
  font-weight: 200;
  color: black;
  font-size: 0.7rem;
}

.made {
  margin-left: auto;
}

.cursor {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    font-size: 0.7rem;
  }
  .credits {
    margin-bottom: 5%;
  }
}
