.footer {
  margin-top: 2%;
  padding: 0% 150px;
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.footer-logo {
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(
      -5px
    ); // adjust the value for the desired move-up distance
  }
  img {
    width: 40%;
  }
}

.footer-column {
  flex-basis: 100%;
  margin-bottom: 20px;
}

.footer-hover {
  li {
    cursor: pointer;
    transition: transform 0.2s ease;
  }
}

.footer-hover li:hover {
  transform: translateY(-5px);
}

.footer-column h4 {
  font-size: 18px;
  color: #31568a;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-column li {
  margin-bottom: 5px;
}

.days {
  display: flex;
  margin-left: -5%;
  .uren {
    margin-left: 0%;
  }
}
.contact {
  margin-left: 10%;
  .social-icons {
    margin-top: 8%;
    margin-left: -10%;
  }
}

.hover-effect {
  transition: transform 0.5s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(
      -5px
    ); // adjust the value for the desired move-up distance
  }
}

@media screen and (max-width: 768px) {
  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 8% 20px;
    line-height: 25px;
  }

  .footer-logo {
    margin-bottom: 20px;
    margin-right: 0;
    justify-content: center;
    img {
      width: 30%;
    }
  }

  .footer-column {
    flex-basis: 100%;
    margin-bottom: 20px;
    width: 100%;
  }

  .footer-column:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }

  .footer-column h4 {
    margin-bottom: 0px;
    font-size: 1rem;
  }
  li {
    color: #232222;
    font-size: 0.9rem;
  }

  .days {
    margin-left: 0%;
    .uren {
    }
  }

  .contact {
    margin-left: 0;
    .social-icons {
      margin-top: 5%;
      margin-left: 0%;
    }
  }
}
