$textColor: #232222;
$titleColor: #31568a;
$backgroundColor: #e9e9e9;
$backgroundColorNav: #23a7d7;
$buttonHover: #25a18e;

.hero-container {
  .hero-wrap {
    align-items: center;
    padding: 4% 150px;
    .wrap {
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  .image {
    display: flex;
    justify-content: end;
    img {
      width: 70%;
    }
  }
  h1 {
    text-decoration: none;
    position: relative;
    font-size: 45px; /* adjust to match your font size */
    font-weight: 700; /* adjust to match your font weight */
    color: $titleColor; /* adjust to match your text color */
    display: inline-block;
    padding-bottom: -90%; /* adjust to match your desired distance between text and underline */
    line-height: 40px;
  }

  h1::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px; /* adjust to match your desired line thickness */
    background-color: #25a18ea7; /* adjust to match your desired line color */
    border-radius: 50px; /* adjust to match your desired roundedness of the edges */
    z-index: -1; /* move the underline behind the text */
  }

  .text {
    width: 80%;
    .bio {
      margin: 10px 0px;
      h3 {
        font-weight: 200;
        line-height: 40px;
        font-size: 1.5rem;
      }
    }
  }
  button {
    background-color: $titleColor;
    margin-top: 20px;
    border: none;
    color: $backgroundColor;
    padding: 10px 25px;
    text-align: center;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    h3 {
      padding-top: 3px;
      text-decoration: none;
      font-weight: 800;
      font-size: 16px;
    }
  }

  button:hover {
    background-color: $buttonHover;
  }
}

@media only screen and (max-width: 1024px) {
  .hero-container {
    .hero-wrap {
      padding: 5% 20px;
      .wrap {
        display: block;
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
    }
    .image {
      display: flex;
      justify-content: center;
      img {
        width: 90%;
      }
    }
    .text {
      width: 100%;
      margin: 30px 0px;
      .bio {
        margin: 10px 0px;
        h3 {
          font-size: 1.12rem;
          font-weight: 300;
          line-height: 30px;
        }
      }
    }
    button {
      margin-top: 10px;
    }
  }
}
