html {
  scroll-behavior: smooth;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Overpass", sans-serif;
}
body {
  background-color: #e9e9e9;

}
